import { MOBILE_QUERY_MAX_WIDTH_M } from "constants/vp-sizes";
import styled from "@emotion/styled";
export const StyledSelectableTagsGridButtonComponent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;

  .secondary-button-wrapper,
  .youtube-button-wrapper {
    height: 50px;
    display: flex;
    align-items: center;
  }

  .button-wrapper {
    &.shake-button {
      animation: shakeAnimation 0.5s 1;
      animation-timing-function: ease-in-out;
    }

    @keyframes shakeAnimation {
      0% {
        transform: scale(1);
      }

      15% {
        transform: scale(1.05);
      }

      35% {
        transform: scale(0.989);
      }

      67.5% {
        transform: scale(1.005);
      }

      100% {
        transform: scale(1);
      }
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH_M}) {
    flex-direction: column;
    align-items: center;
  }
`;
